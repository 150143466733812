import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import store from "../redux/store";
import authSlice from "../redux/slices/auth";
import {API_URL} from "../constants/common";

const axiosService = axios.create({
    baseURL: API_URL,
});

axiosService.interceptors.request.use(async (config) => {
    const {token} = store.getState().auth;

    if (token !== null) {
        config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
});

axiosService.interceptors.response.use(
    (res) => {
        return Promise.resolve(res);
    },
    (err) => {
        const response = Promise.reject(err)
        if (err.response.status === 404) {
            window.location.href = '/not-found'
        }
        return response
    }
);

const refreshAuthLogic = async (failedRequest: { response: { config: { headers: { Authorization: string; }; }; }; }) => {
    const {refreshToken} = store.getState().auth;
    if (refreshToken !== null) {
        return axios
            .post(
                '/auth/refresh/',
                {
                    refresh: refreshToken,
                },
                {
                    baseURL: API_URL
                }
            )
            .then((resp) => {
                const {access, refresh} = resp.data;
                failedRequest.response.config.headers.Authorization = 'Bearer ' + access;
                store.dispatch(
                    authSlice.actions.setAuthTokens({token: access, refreshToken: refresh})
                );
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    store.dispatch(authSlice.actions.logout());
                }
            });
    }
};

createAuthRefreshInterceptor(axiosService, refreshAuthLogic);

export default axiosService;