import React from "react";
import {Grid} from "@mui/material";
import Chips from "../../../components/Chips";
import {BountyApplicationsResponse, BountyResponse} from "../../../interfaces/responses/Bounty";
import {salaryRange} from "../../../utils/currency";

interface BountyDetailChipsProps {
    bounty?: BountyResponse | BountyApplicationsResponse
}

export default function BountyDetailsChips({bounty}: BountyDetailChipsProps) {
    return (
        <Grid container direction="row" justifyContent='center'>

            {bounty?.marketplaces?.map((marketplace) =>
                <Chips icon={<label>🏛️</label>} label={marketplace.marketplace.title} key={`marketplace ${marketplace.id}`}/>)
            }

            {(bounty?.salary_range_start && bounty.salary_range_end) &&
                <Chips icon={<label>💰</label>} label={salaryRange(bounty.salary_range_start, bounty.salary_range_end)} key={'salaryRange'}/>}

            {bounty?.employment_arrangements?.map((arrangement) =>
                <Chips icon={<label>⏳</label>} label={arrangement.employment_arrangement.arrangement} key={`employment_arrangement ${arrangement.id}`}/>)}


            {bounty?.location && <Chips icon={<label>🌐</label>} label={bounty.location} key={'location'}/>}

            
            {bounty?.employment_types?.map((employment_types) =>
            {
                if (["full time", 'full-time'].includes(employment_types.employment_type.type.toLowerCase())) {
                    return
                }
                return <Chips icon={<label>⚓</label>} label={employment_types.employment_type.type} key={`employment_type ${employment_types.id}`}/>})
            }

            {bounty?.helping_with_relocate ? <Chips icon={<label>💸</label>} label="relocation" key={'relocation'}/> : <>{bounty?.helping_with_relocate}</>}
        </Grid>
    )
}