import MuiMarkdown from "mui-markdown";
import React, {memo} from "react";
import {Box, Chip, Typography} from "@mui/material";
import {BountyDetailResponse} from "interfaces/responses/Bounty";

interface BountyDetailChipsProps {
    bounty?: BountyDetailResponse
}

function BountyDescription({bounty}: BountyDetailChipsProps) {
    return (
        <>
            {(!!bounty?.description || !!bounty?.technologies?.length) &&
            <Box sx={{wordBreak: 'break-word'}}>
                <MuiMarkdown overrides={{
                    div: {props: {style: {fontSize: '14px'}}},
                    ul: {props: {style: {fontSize: '14px'}}},
                    td: {props: {style: {fontSize: '14px'}}},
                }}>
                    {bounty?.description}
                </MuiMarkdown>
                {!!bounty?.technologies?.length &&
                    <Box marginTop="2rem">
                        <Typography variant='subtitle3'>Technologies</Typography>
                        <Box>
                            {bounty.technologies.map((technology) =>
                                <Chip
                                    variant='outlined'
                                    sx={{mr: 1}}
                                    key={technology.technology.title}
                                    label={technology.technology.title.toUpperCase()}/>
                            )}
                        </Box>
                    </Box>}
            </Box>
            }
        </>
    )

}

export default memo(BountyDescription)