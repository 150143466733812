import * as React from 'react';
import { Grid, Paper } from '@mui/material';
import {BountyResponse} from '../../../interfaces/responses/Bounty';
import {useNavigate} from "react-router-dom";
import {THEME_COLORS} from "../../../constants/colors";

import {BountyMainInfoCardSmall} from 'pages/BountyDetail/components/BountyMainInfoCard';

interface BountyProps {
    bounty: BountyResponse;
}

export default function BountyCard(props: BountyProps) {
    const {bounty} = props
    const navigation = useNavigate();


    const bountyDetail = () => {
        navigation(`/bounty/${bounty.id}`)
    }

    return (
        <Grid item key={bounty.id} onClick={bountyDetail} sx={{cursor: 'pointer'}} >
            <Paper className="styled-hover" sx={{pt: '25px', pb: "15px", px: '35px', margin: 'auto', background: THEME_COLORS.background, boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'}}>
                <BountyMainInfoCardSmall bounty={bounty}/>
            </Paper>
        </Grid>
    );
}