import React from "react";
import {BountyApplicationsResponse} from "../../../interfaces/responses/Bounty";
import {Box, Paper} from "@mui/material";
import {THEME_COLORS} from "../../../constants/colors";
import ApplicationTab from "./ApplicationTab";
import {BountyMainInfoCardSmall} from "pages/BountyDetail/components/BountyMainInfoCard";

interface BountyApplicationsCardProps {
    bounty: BountyApplicationsResponse
}

export default function BountyApplicationsCard({bounty}: BountyApplicationsCardProps) {
    return (
        <Paper sx={{background: THEME_COLORS.background, mx: 'auto', py: '25px', px: '35px', mb: 5, boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'}} >
            <BountyMainInfoCardSmall bounty={bounty}/>
            <Box mt={"10px"}>
                {bounty.applications.map(application => <Box key={`ApplicationTab ${application.id} ${bounty.id}`}><ApplicationTab application={application}/></Box>)}
            </Box>
        </Paper>
    )
}