import {useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {TechnologyResponse} from "../../../interfaces/responses/Technology";
import {RootState} from "../../../redux/store";
import axiosService from "../../../requests/axios";
import technologySlice from "../../../redux/slices/technology";
import {Autocomplete, Box, Chip, Paper, TextField, Typography} from "@mui/material";
import {THEME_COLORS} from "../../../constants/colors";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';


type Props = {
    values: TechnologyResponse[]
    setValue: (values: TechnologyResponse[]) => void;
};

function TechnologiesSelect({values, setValue}: Props) {

    const dispatch = useDispatch();
    const listTechnology = useSelector((state: RootState) => state.technology);
    const options = listTechnology.technologies ?? []


    useEffect(() => {
        axiosService.get<TechnologyResponse[]>("technologies/")
            .then((res) => {
                dispatch(
                    technologySlice.actions.setTechnologies(res.data)
                );
            })
            .catch((err) => {
            });

    }, [])

    return (
        <>
            <Autocomplete
                sx={{mt: 2}}
                renderTags={(options) =>
                    <Typography variant="body2"
                        sx={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: "calc(100% - 40px)"}}>
                        {options.map(option => option.title).join(', ')}
                    </Typography>}
                multiple
                options={options}
                getOptionLabel={option => option.title}
                disableCloseOnSelect
                onChange={(event, value) => {
                    setValue(value);
                }}
                value={values ?? []}
               
                renderInput={(params) => {
                    return (
                        <Box>
                            <TextField
                                {...params}
                                InputLabelProps={{...params }}
                                sx={{
                                    flexWrap: 'nowrap',
                                    background: THEME_COLORS.surface,
                                }}
                                label='Choose Your Technologies'
                            />
                        </Box>
                    )
                }}
            />
            <Box>
                {values?.map((technology) => <Chip
                    variant='outlined'
                    sx={{mt: 1, mr: 1, background: THEME_COLORS.surface}}
                    deleteIcon={<ClearOutlinedIcon/>}
                    onDelete={() => {
                        setValue(values?.filter(entry => entry !== technology))
                    }}
                    key={technology.id}
                    label={technology.title.toUpperCase()}/>)}
            </Box>
        </>
    )
}

export default TechnologiesSelect