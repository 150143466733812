import React from 'react';
import { Grid, LinearProgress, Typography } from '@mui/material';


export default function CustomLinearProgress() {

  return (
    <Grid className="loading-overlap" display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="60vh" >
      <Grid alignItems="flex-start" width={{xs:"15.625rem", sm: "22rem", md: "25rem", lg: "30rem"}}>
        <Typography variant="subtitle3" color={(theme) => theme.palette.secondary?.contrastText}>LOADING...</Typography>
        <LinearProgress />
      </Grid>
    </Grid>
  );
}
