import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {BountyDetailResponse} from "../../interfaces/responses/Bounty";


export type bountyState = {
    bounty: BountyDetailResponse | null
};

const initialState: bountyState = {
    bounty: null
}

const bountySlice = createSlice({
    name: "bounty",
    initialState,
    reducers: {
        setBounty(
            state: bountyState,
            action: PayloadAction<BountyDetailResponse>
        ) {
            state.bounty = action.payload
        },
    }
});

export default bountySlice;