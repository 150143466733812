import React, {useState} from "react";
import {THEME_COLORS} from "../constants/colors";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {Box, Icon, InputAdornment, TextField, Typography} from "@mui/material";
import {APPLICATION_RESUME_ALLOWED_EXTENSION} from "../constants/common";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


interface DragDropProps {
    file: File | null
    setFile: (file: File) => void
}

export default function DragDrop({file, setFile}: DragDropProps) {
    const [fileChosen, setFileChosen] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const target = e.currentTarget as HTMLInputElement;
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        setFileChosen(true)
        setFile(target.files![0])
    };
    return (
        <>
        <Box position='relative'>
            <TextField
                error={fileChosen && !file}
                value={file ? file.name : ''}
                label='Drag to upload your resume'
                required
                sx={{background: THEME_COLORS.surface, position: "absolute", width: "100%", textOverflow: 'ellipsis'}}/>
            <Icon sx={{zIndex: 10, position: 'absolute', top: '30%', right: '10px'}}>
                <CloudUploadOutlinedIcon color="primary"/>
            </Icon>
            <TextField type='file' fullWidth onChange={handleChange}
                       sx={{opacity: 0, z: 1}} inputProps={{accept: APPLICATION_RESUME_ALLOWED_EXTENSION}}/>
        </Box>
            {fileChosen && !file && (
                <Box display='flex' mt={'3px'}>
                    <ErrorOutlineIcon sx={{height: '17px', color: THEME_COLORS.error}}/>
                    <Typography variant={'body5'} sx={{color: THEME_COLORS.error}} >Resume is required</Typography>
                </Box>)}
        </>
    )
}
