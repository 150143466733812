import { Chip } from "@mui/material";
import React from "react";
import {THEME_COLORS} from "../constants/colors";

interface ChipProps {
    label: string
    icon: React.ReactElement
}

export default function Chips({label, icon}: ChipProps) {
    return (
            <Chip sx={{minWidth: 130, mr: 2, my:1,  borderRadius: "4px", fontSize: '12px', borderColor: THEME_COLORS.border }} icon={icon} variant="outlined" label={label}/>
    )

}