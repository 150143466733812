import { Typography, Link, Chip, Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { THEME_COLORS } from "../../constants/colors";
import store, { RootState } from '../../redux/store'
import BountyDescription from "../Bounties/components/BountyDescription";
import BountyMainInfoCard from "../BountyDetail/components/BountyMainInfoCard";
import { receiveApplicationDetails } from "./actions/ApplicationDetailsActions";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApplicationStatus, UserRole } from "constants/enums";
import axiosService from "requests/axios";
import CustomLinearProgress from "components/CustomLinearProgress";
import AlertMessage from "components/AlertMessage";
import { AlertMessageType, ERROR_CODE, SUCCESS_CODE } from "constants/common";
import CandidateResume from "./components/CandidateResume";
import {boxShadowMainCard} from "../../constants/styles";


export default function ApplicationDetails() {
    const {applicationId} = useParams()

    const { dispatch } = store
    const { application, loading } = useSelector((state: RootState) => state.applicationDetails);
    const [disabledButton, setDisabledButton] = useState(false)
    const auth = useSelector((state: RootState) => state.auth);
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    const [showFeedbackField, setShowFeedBackField] = useState(false)
    const [ feedback, setFeedback ] = useState('')

    const navigate = useNavigate()

    const [showMore, setShowMore] = useState(false);

   const showStatus = () => {
    const userRole = auth.account?.role
    switch (application?.status) {
        case ApplicationStatus.RECRUITER_REVIEWING:
            return <Typography variant='subtitle1' sx={{color: THEME_COLORS.review}}>new</Typography>
        case ApplicationStatus.RECRUITER_REJECTED:
            return <Typography variant='subtitle1' sx={{color: THEME_COLORS.error}}>declined</Typography>
        case ApplicationStatus.COMPANY_REVIEWING:
            return (
                <Typography variant='subtitle1' sx={{color: userRole == UserRole.RECRUITER ? THEME_COLORS.pending : THEME_COLORS.review }}>
                    {userRole == UserRole.RECRUITER ?  'pending' : 'new'}
                </Typography>
            )
        case ApplicationStatus.COMPANY_REJECTED:
            return (
                <Typography variant='subtitle1' sx={{color: THEME_COLORS.error}}>
                    {userRole == UserRole.EMPLOYER ? "declined" : "declined by company"}
                </Typography>
            )
        case ApplicationStatus.HIRED:
            return <Typography variant='subtitle1' sx={{color: THEME_COLORS.success}}>hired</Typography>
        case ApplicationStatus.COMPANY_INTERVIEWING:
            return <Typography variant='subtitle1' sx={{color: THEME_COLORS.pending}}>interviewing</Typography>
        default:
            return <></>
    }
}

    useEffect(() => {
        if (applicationId) {
            dispatch(receiveApplicationDetails(applicationId))
        }
    }, [])

    const onDeclineOrSubmit = (action: string) => {
        setDisabledButton(true);
        let message = '';
        if (action === "reject"){
            message = "Application was declined and feedback was successfully created!"
            if (auth.account?.role === UserRole.EMPLOYER){
                message = "Application was rejected and feedback was successfully created!"
            }
        }
        else {
           message = "Application was successfully approved!"
           if (auth.account?.role === UserRole.EMPLOYER){
               message = application?.status == ApplicationStatus.COMPANY_REVIEWING ? 'Candidate was successfully taken for interviewing' : "Candidate was successfully hired!"
            }
        }

        axiosService.post(`/application/${action}/${applicationId}/`).then((res) => {
            if (action === "reject"){
                axiosService.post(`/application/notes/${applicationId}/`, {content: feedback})
            }
            setMessage({message: message, code: SUCCESS_CODE});
            setTimeout(() => {
                navigate('/my-candidates')
            }, 2000);
        })
            .catch((err) => {
                setMessage({message: "Something went wrong!", code: ERROR_CODE});
                setDisabledButton(false);
            });
    }

    useEffect(() => {
        if (application?.status == ApplicationStatus.COMPANY_REJECTED || application?.status == ApplicationStatus.HIRED){
            setDisabledButton(true)
        }
    }, [application])


    return (
        <> 
            <Box>{message && 
                <AlertMessage setMessage={setMessage} message={message.message} code={message.code}/>}
            </Box>
            {!loading && application ? 
                <Grid mx={20} padding={'56px'}
                      sx={{background: THEME_COLORS.background, borderRadius: '8px', boxShadow: boxShadowMainCard}}>
                    {<BountyMainInfoCard bounty={application?.bounty!}/>}
                    { (!!application.bounty?.description || !!application.bounty?.technologies?.length) &&
                        <>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end"  onClick={() => setShowMore(!showMore)}>
                        <Typography sx={{mt: 2}} variant="subtitle3" color={(theme) => theme.palette?.primary?.dark}>
                            {showMore ? "LESS" : "MORE"}
                        </Typography>
                         { showMore ? <ExpandLessIcon fontSize="large" sx={{color: (theme) => theme.palette?.secondary?.main, paddingTop: "4px"}}/> :
                         <ExpandMoreIcon fontSize="large" sx={{color: (theme) => theme.palette?.secondary?.main,  paddingTop: "4px"}}/>}
                    </Grid>
                    {showMore && <BountyDescription bounty={application.bounty}/>}
                            </>
                    }
                    <Grid container justifyContent='space-between' sx={{mt: '90px'}}>
                        <Typography variant="subtitle1" color={(theme) => theme.palette?.secondary.dark}>CANDIDATE</Typography>
                        {showStatus()}
                    </Grid>
                    <Grid container justifyContent='space-between'>
                        <Typography variant="h4" component="div" color={(theme) => theme.palette?.secondary.dark}>
                            {application.first_name}  {application.last_name}
                        </Typography>
                        <Typography variant="body1" style={{marginBottom: "20px"}} color={(theme) => theme.palette?.secondary.dark}>{application.email}</Typography>
                    </Grid>
                    {/*{ auth?.account?.role == UserRole.RECRUITER && application?.target_compensation_from && application?.target_compensation_to &&*/}
                    {/*<>*/}
                    {/*<Typography variant="subtitle3" component="div" style={{marginTop: "24px"}}>Target Compensation Range</Typography>*/}
                    {/*<Typography variant="body2" color={(theme) => theme.palette?.primary.dark}>{salaryRange(application.target_compensation_from, application.target_compensation_to)}</Typography>*/}
                    {/*</>}*/}
                    {application?.note && <>
                    <Typography variant="subtitle3" component="div" style={{marginTop: "24px"}}>Cover Note</Typography>
                    <Typography variant="body2" marginBottom="1.2rem" color={(theme) => theme.palette?.primary.dark}>{application.note}</Typography>
                    </>}
                    {application?.technologies.length > 0 &&
                    <>
                        <Box>
                            <Typography variant='subtitle3'>Technologies</Typography>
                            <Box>
                                {application?.technologies.map((technology) =>
                                    <Chip
                                        variant='outlined'
                                        key={technology.technology.title}
                                        label={technology.technology.title.toUpperCase()}/>
                                    )}
                                </Box>
                            </Box>
                        </>}
                    <Grid container justifyContent="flex-end" my={'40px'}>
                        {application?.linkedin &&
                            <Link variant="subtitle2" href={application?.linkedin} target="_blank" sx={{ mx: 1 }}>
                                linkedin
                            </Link>}
                        <Link variant="subtitle2" href={application?.resume} target="_blank" sx={{ mx: 1 }}>
                            resume
                        </Link>
                    </Grid>
                    <CandidateResume/>
                    {!showFeedbackField ?
                        <>
                            {(auth.account?.role == UserRole.RECRUITER && application.status == ApplicationStatus.RECRUITER_REVIEWING) &&
                                <Grid display="flex" justifyContent="center" style={{marginTop: "56px"}}>
                                <Button variant="outlined" disabled={disabledButton} sx={{px: '40px', mx:'20px', height: '50px'}} onClick={() => setShowFeedBackField(true)}>
                                    DECLINE
                                </Button>
                                <Button variant="contained" disabled={disabledButton}  sx={{px: '40px', mx:'20px', height: '50px'}}  onClick={() => onDeclineOrSubmit('confirm')}>
                                    APPROVE
                                </Button>
                                </Grid>
                            }
                            {(auth.account?.role === UserRole.EMPLOYER && [ApplicationStatus.COMPANY_REVIEWING, ApplicationStatus.COMPANY_INTERVIEWING].includes(application.status)) &&
                                <Grid display="flex" justifyContent="center" style={{marginTop: "56px"}}>
                            <Button variant="outlined" disabled={disabledButton}  sx={{px: '40px', mx:'20px', height: '50px'}}  onClick={() => setShowFeedBackField(true)}>
                                DECLINE
                            </Button>
                            <Button variant="contained" disabled={disabledButton}  sx={{px: '40px', mx:'20px', height: '50px'}}  onClick={() => onDeclineOrSubmit('confirm')}>
                                {application.status === ApplicationStatus.COMPANY_REVIEWING ? "INTERVIEW" : "HIRE"}
                            </Button>
                                </Grid>}
                        </> :
                        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="60vh">
                            <Box display="flex" flexDirection="column" alignItems="center" width="56.25rem" minHeight="36.875rem" padding="4.5rem 6.375rem 2rem 6.375rem" sx={{backgroundColor: (theme) => theme.palette.primary?.light}}>
                                <Typography variant="h4" textAlign="center" color={(theme) => theme.palette.secondary?.dark}>Create feedback</Typography>
                                <Typography variant="body1" mt="0.625rem" textAlign="center" color={(theme) => theme.palette.primary?.dark}>
                                Please share your feedback regarding the candidate!
                                </Typography>
                                <TextField multiline fullWidth minRows={8} sx={{width: "43.125rem", marginTop: "3rem", backgroundColor:"inherit"}}
                                value={feedback} onChange={(e) => setFeedback(e.target.value)} label="This information is undisclosed!"></TextField>
                                <Grid display="flex" justifyContent="center" style={{marginTop: "3rem"}}>
                                    <Button variant="contained" disabled={!feedback || disabledButton} style={{margin: "0 20px", padding: "24px 40px"}} onClick={() => onDeclineOrSubmit('reject')}>
                                        SUBMIT
                                    </Button>
                                </Grid>
                            </Box>
                        </Box>
                    }
                    </Grid>

                : <CustomLinearProgress/>
            } 
        </>
    )
}
