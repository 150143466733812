import {createTheme} from "@mui/material";
import {THEME_COLORS} from "../constants/colors";
import GradientTop from "../assets/images/GradientTop.svg"
import GradientBottom from "../assets/images/GradientBottom.svg"
import '../styles/common.css';

export const appTheme = createTheme({
    breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1025,
          xl: 1300,
        },
      },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                html: {
                    height: "100%",
                    width: "100%",
                },
                body: {
                    ".background": {
                        "&::before": {
                            backgroundImage: `url(${GradientTop}), url(${GradientBottom})`,
                            backgroundColor: THEME_COLORS.background,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: "cover",
                            content: '""',
                            position: 'fixed',
                            zIndex: -1,
                            width: '100%',
                            height: '100%',
                            willChange: 'transform',
                            top: 0,
                            left: 0
                        },
                    }
                }
            },
        },

        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: '0px 4px 20px rgba(37, 143, 106, 0.14)',
                    backgroundImage: 'linear-gradient(to right, #C3DFD8, #CBE0DB, #D8E6E2, #D8E7E3, #DCE8E6, #DDE9E4, #E0E9E7, #D9E9E3, #DFEAE4, #DBE8E4, #DEE8E4, #E0E8E2, #E4E8E2, #E4E8E2, #E1E7E0, #E8EBE6)'
                }
            }
        },

        MuiChip: {
          styleOverrides: {
            root: {
              textTransform: "uppercase",
            },
          },
        },

        MuiTextField: {
            styleOverrides: {
              root: {
                boxShadow: 'none',
                textAlign: 'center',
                backgroundColor: THEME_COLORS.background,
                borderRadius: '4px',
                borderWidth: '1px',
                  "& .MuiSvgIcon-root": {
                    color: `${THEME_COLORS.secondary} !important`
                  },
                '& .MuiOutlinedInput-root': {
                    alignItems: "center",
                    minHeight: '4.375rem',
                    textAlign: 'center',
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "18px",
                    fontFamily: 'Archivo',
                    '&.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: THEME_COLORS.primary,
                          borderWidth: '1px',
                        },
                      },
                    '& fieldset': {
                    borderColor: THEME_COLORS.border,
                    }, 
                    '&:hover fieldset': {
                        borderColor: THEME_COLORS.primary,
                        boxShadow: '0px 0px 8px rgba(37, 143, 106, 0.25)',
                    },
                },
                '& .MuiInputLabel': {
                    root: {
                        margin: "8px",
                    }
                },
                '& label': {
                    color: THEME_COLORS.surfaceDivider,
                    backgroundColor: 'inherit !important',
                },
              },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Archivo',
                    fontStyle: "normal",
                    fontWeight: "300",
                    fontSize: "18px",
                    margin: "8px",
                },
                shrink:{
                    color: THEME_COLORS.primary,
                    backgroundColor: THEME_COLORS.background,
                    fontSize: "20px",
                    margin: "1px !important",
                    width: "auto",
                    paddingRight: "3px !important",
                    marginLeft:"-4px !important",
                }
            }
        },

        MuiFormLabel: {
            styleOverrides: {
                asterisk: {
                    color: THEME_COLORS.primary,
                    '&$error': {
                        color: '#db3131'
                    },
                }
            }
        },

        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    color: THEME_COLORS.primary,
                    fontFamily: 'Archivo',
                    fontSize: "18px !important",
                    fontWeight: "300px !important",
                },
                inputRoot: {
                    alignItems: "center",
                },
                popper: {
                    overflowY: "scroll",
                    height: "12.5rem",
                    marginTop: "0.5rem !important",
                    background: THEME_COLORS.surface,
                    boxShadow: "0px 0px 4px rgba(37, 143, 106, 0.25), 0px 4px 20px rgba(37, 143, 106, 0.4)",
                    borderRadius: "4px",

                    '& .MuiAutocomplete-option': {
                        fontFamily: 'Archivo',
                        fontSize: "16px",
                        fontWeight: "300px",
                        color: THEME_COLORS.contentAccessory,
                        '&:hover': {
                            backgroundColor: `${THEME_COLORS.backgroundControls} !important`,
                        }
                    },

                    '& .MuiAutocomplete-option[aria-selected="true"]': {
                        backgroundColor: "transparent !important",
                        fontSize: "18px",
                        color: THEME_COLORS.contentSupporting,

                        '&:hover': {
                            backgroundColor: `${THEME_COLORS.backgroundControls} !important`,
                        }
                    },                    
                }, 
            }
        },

        MuiButton: {
            styleOverrides: {
              root: {
                borderRadius: "4px",
                textTransform: 'none',
              },
              textPrimary: {
                "&:hover": {
                    backgroundColor: 'transparent',
                    "span, h6": {
                        color: THEME_COLORS.primary,
                    }
                },

              },
              sizeSmall: {
                fontFamily: 'Clash Display',
                fontSize: "1rem",
                height: "3.375rem",
                textTransform: "uppercase",
              },
              sizeMedium: {
                fontFamily: 'Clash Display',
                fontSize: "1.25rem",
                height: '4.25rem',
                textTransform: "uppercase",
              },
              sizeLarge: {
                fontFamily: 'Clash Display',
                fontSize: "1.5rem",
                height: "4.875rem",
                textTransform: "uppercase",
              },
              contained: {
                backgroundColor: THEME_COLORS.secondaryVariant,
                color: THEME_COLORS.onSecondaryVariant,
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: THEME_COLORS.primary,
                },
                "&:disabled": {
                    backgroundColor: THEME_COLORS.border,
                    color: THEME_COLORS.surfaceDivider,
                  },
              },
              outlined: {
                borderColor: THEME_COLORS.secondaryVariant,
                color: THEME_COLORS.secondaryVariant,
                '&:hover': {
                    backgroundColor: THEME_COLORS.backgroundControls,
                    color: THEME_COLORS.primary,
                },
                "&:disabled": {
                    borderColor: THEME_COLORS.disabled,
                    backgroundColor: THEME_COLORS.surface,
                    color: THEME_COLORS.disabled,
                  },
              },
              text: {
                color: THEME_COLORS.surfaceDivider,
                padding: 0,
              },
            },
          },
          MuiSnackbar: {
            styleOverrides: {
                root: {
                    backgroundColor: THEME_COLORS.background,
                    borderColor: THEME_COLORS.border,
                    boxShadow: ' 0px 2px 15px rgba(37, 143, 106, 0.15)',
                }
            },
        },

        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    backgroundColor: "transparent",
                    height: "1.5rem",
                    border: "1px solid",
                    borderRadius: "2px",
                    borderImageSlice: 1,
                    borderImageSource: "linear-gradient(90deg, #EEE5C0 0%, #42A180 107.52%)",

                },
                bar: {
                    backgroundImage: "linear-gradient(90deg, #EEE5C0 0%, #42A180 107.52%)",
                    margin: "0.1rem",
                    borderRadius: "2px",
                }
            },
        },

        MuiAlert: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    fontSize: '16px',
                    fontWeight: 300,
                    fontFamily: 'Archivo',
                    color: THEME_COLORS.surfaceDivider,
                    borderColor: THEME_COLORS.border,
                    boxShadow: '0px 2px 15px rgba(0, 91, 59, 0.1)',
                },
                icon: {
                    alignItems: "center",
                    '& svg': {
                        width: "1.7rem",
                        height: "1.7rem",
                    }
                }, 
                message: {
                    marginRight: "2.4rem"
                }
            }
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    fontFamily: 'Archivo',
                    fontSize: '24px',
                    fontWeight: 400,
                    color: THEME_COLORS.contentSupporting,
                }
            }
        },

        MuiTypography: {
            styleOverrides: {
                root: {
                    wordBreak: "initial",
                }
            }
        },
      },

    palette: {
        primary: {
            main: THEME_COLORS.primary,
            dark: THEME_COLORS.contentAccessory,
            light: THEME_COLORS.surface,
            contrastText: THEME_COLORS.background
        },
        secondary: {
            main: THEME_COLORS.secondary,
            dark: THEME_COLORS.contentLeading,
            light: THEME_COLORS.border,
            contrastText: THEME_COLORS.surfaceDivider,
        }
    },

    typography: {
        h1: {
            fontFamily: 'Clash Display',
            fontWeight: 600,
            fontSize: 70,
            textTransform: "uppercase",
            color: THEME_COLORS.secondaryVariant
        },
        h2: {
            fontFamily: 'Clash Display',
            fontWeight: 500,
            fontSize: 48,
            textTransform: "uppercase",
            color: THEME_COLORS.contentLeading
        },
        h3: {
            fontFamily: 'Clash Display',
            fontSize: 40,
            fontWeight: 500,
            textTransform: "uppercase",
            color: THEME_COLORS.contentLeading,
        },
        h4: {
            fontFamily: 'Clash Display',
            fontSize: 32,
            fontWeight: 600,
            textTransform: "uppercase",
            color: THEME_COLORS.primary
        },

        subtitle1: {
            fontFamily: 'Clash Display',
            fontSize: 28,
            fontWeight: 500,
            textTransform: "uppercase",
            color: THEME_COLORS.primary
        },
        subtitle2: {
            fontFamily: 'Clash Display',
            fontSize: 24,
            fontWeight: 600,
            textTransform: "uppercase",
            color: THEME_COLORS.contentAccessory
        },
        subtitle3: {
            fontFamily: 'Clash Display',
            fontSize: 18,
            fontWeight: 500,
            textTransform: "uppercase"
        },

        body1: {
            fontFamily: 'Archivo',
            fontSize: 24,
            fontWeight: 400,
        },
        body2: {
            fontFamily: 'Archivo',
            fontSize: 18,
            fontWeight: 300,
        },
        body3: {
            fontFamily: 'Archivo',
            fontSize: 16,
            fontWeight: 300,
        },
        body4: {
            fontFamily: 'Archivo',
            fontSize: 12,
            fontWeight: 500,
        },
        body5: {
            fontFamily: 'Archivo',
            fontSize: 12,
            fontWeight: 300,
        },

        buttonSmall: {
            fontFamily: 'Clash Display',
            fontSize: 16,
            fontWeight: 500,
        },
        buttonMiddle: {
            fontFamily: 'Clash Display',
            fontSize: 20,
            fontWeight: 500,
        },
        buttonLarge: {
            fontFamily: 'Clash Display',
            fontSize: 24,
            fontWeight: 500,
        }
    },

})
