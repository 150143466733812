import * as React from 'react';

import { AppBar, Box, Toolbar, Typography, Button, Grid } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import authSlice from "../redux/slices/auth";
import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import { RootState } from '../redux/store';
import {THEME_COLORS} from "../constants/colors";

interface ToolBarProps {
  isProtected?: boolean
}

export default function ToolBar({isProtected}: ToolBarProps) {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);

    const handleLogout = () => {
        dispatch(authSlice.actions.logout());
        navigation("/login");
    };

    const currentPath = location.pathname
    return (
      <AppBar position="fixed" elevation={0} sx={{height: "70px"}}>
        <Grid sx={{mx: 5}}>
          <Toolbar disableGutters sx={{height:"70px"}}>
          {!auth.account || !isProtected ?
            <Typography variant="h4" component="a" sx={{textDecoration: 'none'}}>
              ORBIT
            </Typography>  :
            <>
            <Typography variant="h4" component="a" href="/bounties" sx={{textDecoration: 'none'}}>
              ORBIT
            </Typography>
            <Box sx={{ flexGrow: 2, display: { xs: 'none', md: 'flex', justifyContent: "flex-end"}, mr: 10}}>
              <Button disableRipple onClick={() => navigation("/bounties")} sx={{ my: 2, marginRight: "14px"}}>
                  <Typography sx={{color: THEME_COLORS.contentLeading}} variant={currentPath == '/bounties' ? 'subtitle2' : 'subtitle3'}>
                      Open Bounties
                  </Typography>
              </Button>
              <Button disableRipple onClick={() => navigation("/my-candidates")} sx={{ my: 2, color: THEME_COLORS.contentLeading }}>
                  <Typography sx={{color: THEME_COLORS.contentLeading}} variant={currentPath == '/my-candidates' ? 'subtitle2' : 'subtitle3'}>
                      My candidates
                  </Typography>
              </Button>
            </Box>
            <LogoutSharpIcon sx={{color: (theme) => theme.palette?.secondary?.main}} onClick={handleLogout}>Log out</LogoutSharpIcon>
            </>
            }
          </Toolbar>
        </Grid>
     </AppBar>
    );
}