import {Button, Grid, Icon, Typography} from "@mui/material";
import { THEME_COLORS } from "constants/colors";
import React from "react";
import linkedIn from '../assets/images/linkedin.svg'
import Gradient from '../assets/images/GradientFooter.svg'



export default function Footer() {
    return (
        <Grid container position='absolute' bottom={0} height={'130px'} width='100%' alignItems='center'
              sx={{background: THEME_COLORS.primary, backgroundImage: `url(${Gradient})`, backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover', backgroundColor: THEME_COLORS.primary
              }}>
            <Grid container direction='column' width='100%' alignItems={'center'}>
                <Button
                    size='small'
                    sx={{background: THEME_COLORS.secondaryVariant, width: 'max-content', height: 'min-content', py: 1, px: 3,
                        textTransform: 'none', color: THEME_COLORS.onSecondaryVariant
                }}
                    onClick={()=> {window.location.href = "https://www.linkedin.com/";} }
                    startIcon={<Icon sx={{mb: 1}}><img src={linkedIn} height={20} width={20}/></Icon>}
                >
                    Linkedin
                </Button>
                <Typography variant='body5' mt={1} sx={{color: THEME_COLORS.background}}>Ⓒ 2023 Orbit LLC, All rights reserved.</Typography>
            </Grid>
        </Grid>
    )
}