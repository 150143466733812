import React from "react";
import {ApplicationLiteResponse} from "../../../interfaces/responses/Applications";
import {Box, Grid, Typography} from "@mui/material";
import {THEME_COLORS} from "../../../constants/colors";
import {daysAgo} from "../../../utils/time";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {ApplicationStatus, UserRole} from "../../../constants/enums";
import {useNavigate} from "react-router-dom";

interface ApplicationTabProps {
    application: ApplicationLiteResponse
}


export default function ApplicationTab({application}: ApplicationTabProps) {
    const auth = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate()

    const applicationCreateAt = () => {
        const days = daysAgo(application.created_at)
        switch (days) {
            case 0:
                return 'today'
            case 1:
                return 'yesterday'
            default:
                return days + ' days ago'
        }
    }

    const showStatus = () => {
        const userRole = auth.account?.role
        switch (application.status) {
            case ApplicationStatus.RECRUITER_REVIEWING:
                return <Typography variant='body3' sx={{color: THEME_COLORS.review, textTransform: 'uppercase', fontWeight: 500}}>new</Typography>
            case ApplicationStatus.RECRUITER_REJECTED:
                return <Typography variant='body3' sx={{color: THEME_COLORS.error, textTransform: 'uppercase', fontWeight: 500}}>declined</Typography>
            case ApplicationStatus.COMPANY_REVIEWING:
                return (
                    <Typography variant='body3' sx={{color: userRole == UserRole.RECRUITER ? THEME_COLORS.pending : THEME_COLORS.review, textTransform: 'uppercase', fontWeight: 500 }}>
                        {userRole == UserRole.RECRUITER ?  'pending' : 'new'}
                    </Typography>
                )
            case ApplicationStatus.COMPANY_REJECTED:
                return (
                    <Typography variant='body3' sx={{color: THEME_COLORS.error, textTransform: 'uppercase', fontWeight: 500}}>
                        {userRole == UserRole.EMPLOYER ? "declined" : "declined by company"}
                    </Typography>
                )
            case ApplicationStatus.HIRED:
                return <Typography variant='body3' sx={{color: THEME_COLORS.success, textTransform: 'uppercase', fontWeight: 500}}>hired</Typography>
            case ApplicationStatus.COMPANY_INTERVIEWING:
                return <Typography variant='body3' sx={{color: THEME_COLORS.pending, textTransform: 'uppercase', fontWeight: 500}}>interviewing</Typography>
            default:
                return <></>
        }
    }

    // const applicationCompensationRange = (application.target_compensation_from && application.target_compensation_to)
    //     ? salaryRange(application.target_compensation_from, application.target_compensation_to) : null

    return (
        <Grid className="styled-hover" sx={{borderRadius: '4px', border: '1px solid #D0E1DD', background: "transperant", p: "20px", cursor: 'pointer'}} onClick={() => {navigate(`/application/${application.id}`)}}>
            <Grid container direction='row' justifyContent='space-between' >
                <Box>
                        <Typography variant='body3'
                                    sx={{color: THEME_COLORS.contentLeading, textTransform: 'uppercase', fontWeight: 500}}>
                            {application.first_name} {application.last_name}
                        </Typography>
                        {/*{applicationCompensationRange ?*/}
                        {/*    <Typography variant='body2'>{applicationCompensationRange}</Typography> : <></>}*/}
                </Box>
                <Grid>
                    <Grid container direction='row'>
                        <Grid my='auto'>
                            <Typography variant='body4' sx={{color: THEME_COLORS.surfaceDivider, mr: 2}}>
                                {applicationCreateAt()}
                            </Typography>
                            {showStatus()}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}