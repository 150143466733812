import {Box, Button, Container, Grid, Typography} from "@mui/material";
import React from "react";
import {THEME_COLORS} from "../../constants/colors";
import {useNavigate} from "react-router-dom";

export default function NotFoundPage() {
    const navigate = useNavigate()
    return (
        <Grid container m='auto' height={"600px"} width={'1000px'} boxShadow={'0px 8px 28px rgba(20, 20, 43, 0.1)'} borderRadius={'8px'} sx={{backgroundColor: THEME_COLORS.surface, left: '50%', top: '50%'}}>
            <Box alignItems={'center'} textAlign={'center'} m={'auto'}>
                <Typography variant={'h1'} lineHeight={'80px'} mb={'40px'} sx={{color: THEME_COLORS.contentSupporting}}>OOPS!</Typography>
                <Typography variant={'h2'} lineHeight={'59px'} sx={{color: THEME_COLORS.contentSupporting}}>PAGE NOT FOUND!</Typography>
                <Button
                    variant="contained"
                    sx={{py: '24px', px: '100px', mt: "80px", fontSize: 20, fontWeight: 500, color: THEME_COLORS.onSecondaryVariant, backgroundColor: THEME_COLORS.secondaryVariant}}
                    onClick={() => navigate("/")}>
                    back to homepage
                </Button>
            </Box>
        </Grid>

    )
}