import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import axiosService from "../../requests/axios";
import bountySlice from "../../redux/slices/bounty";
import {Box, Grid} from "@mui/material";


import ReferralLink from "./components/ReferralLink";
import {THEME_COLORS} from "../../constants/colors";
import BountyDescription from "../Bounties/components/BountyDescription";
import {AlertMessageType} from "../../constants/common";
import AlertMessage from "../../components/AlertMessage";
import BountyMainInfoCard from "./components/BountyMainInfoCard";
import CustomLinearProgress from "components/CustomLinearProgress";
import {UserRole} from "../../constants/enums";
import {boxShadowMainCard} from "../../constants/styles";


export default function BountyDetail() {
    const params = useParams()
    const dispatch = useDispatch();
    const bounty = useSelector((state: RootState) => state.bounty.bounty);
    const auth = useSelector((state: RootState) => state.auth);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState<AlertMessageType | null>(null);



    useEffect(() => {
        setLoading(true)
        axiosService.get(`bounty/${params.bountyId}/`)
            .then((res) => {
                dispatch(
                    bountySlice.actions.setBounty({
                        id: res.data.id,
                        company: res.data.company,
                        salary_range_start: res.data.salary_range_start,
                        salary_range_end: res.data.salary_range_end,
                        description: res.data.description,
                        location: res.data.location,
                        helping_with_relocate: res.data.helping_with_relocate,
                        position: res.data.position,
                        reward: res.data.reward,
                        status: res.data.status,
                        employment_arrangements: res.data.employment_arrangements,
                        marketplaces: res.data.marketplaces,
                        employment_types: res.data.employment_types,
                        technologies: res.data.technologies,
                    })
                );
                setLoading(false);
            })
            .catch((err) => {
                setMessage({message: err.response.data.message, code: err.response.data.status_code});
                setLoading(false);
            });

    }, [])

    return (
        <>  
            {!loading && bounty ?
            <>
                <Box>{message && <AlertMessage setMessage={setMessage} message={message.message} code={message.code}/>}</Box>
                <Box mx={20} p={'56px'}
                     sx={{background: THEME_COLORS.background, borderRadius: '8px', boxShadow: boxShadowMainCard}}>
                    <BountyMainInfoCard bounty={bounty} redirectToBounty={false}/>
                    {auth.account?.role == UserRole.RECRUITER &&
                    <Grid container justifyContent="end">
                        <ReferralLink/>
                    </Grid>
                    }
                    <Box height={5}/>
                    <BountyDescription bounty={bounty}/>
                </Box>
            </> : <CustomLinearProgress/> 
            }
        </>
    )
}
