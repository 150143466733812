import {Box, Button, Tooltip} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import axiosService from "../../../requests/axios";
import applicationFormSlice from "../../../redux/slices/applicationForm";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {useParams} from "react-router-dom";
import {THEME_COLORS} from "../../../constants/colors";
import AlertMessage from "../../../components/AlertMessage";
import {AlertMessageType} from "../../../constants/common";

export default function ReferralLink() {
    const [showTooltip, setShowTooltip] = useState(false)
    const [loading, setLoading] = useState(false)
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    const dispatch = useDispatch();
    const applicationForm = useSelector((state: RootState) => state.applicationForm);
    const auth = useSelector((state: RootState) => state.auth);
    const params = useParams()
    const textAreaRef = useRef<HTMLTextAreaElement>(null);


    useEffect(() => {
        axiosService.post(`application-forms/`, {recruiter: auth.account?.id, bounty: params.bountyId})
            .then((res) => {
                dispatch(
                    applicationFormSlice.actions.setApplicationForm({
                        id: res.data.id,
                        bounty: res.data.bounty,
                        recruiter: res.data.recruiter,
                        token: res.data.token
                    })
                );
                setLoading(false);
            })
            .catch((err) => {
                setMessage({message: err.response.data.message, code: err.response.data.status_code});
                setLoading(false);
            });

    }, [])

    const unsecuredCopyToClipboard = () => {
        if (textAreaRef.current) {
            textAreaRef.current.style.display = 'block'
            textAreaRef.current?.select()
            try {
                document.execCommand('copy')
            } catch (err) {
                console.error('Unable to copy to clipboard', err)
            }
            textAreaRef.current.style.display = 'none'
        }
    };

    const getApplicationFormUrl = () => {
        if (window.isSecureContext && navigator.clipboard) {
            if (!!applicationForm.token) {
                navigator.clipboard.writeText(location.origin + `/application-form/${applicationForm.token}`);
            }
        } else {
        unsecuredCopyToClipboard()
        }
    }

    return (
        <>
            <Box>{message && <AlertMessage setMessage={setMessage} message={message.message} code={message.code}/>}</Box>
            <textarea ref={textAreaRef} value={location.origin + `/application-form/${applicationForm.token}`} style={{display: "none"}}/>
            <Tooltip open={showTooltip}
                     title={"Copied to clipboard!"}
                     leaveDelay={1500}
                     placement='top'
                     onClose={() => setShowTooltip(false)}
            >
                <Button variant="contained"
                        sx={{mt: 2, background: THEME_COLORS.secondaryVariant, height: 50, width: 200}} onClick={() => {
                    getApplicationFormUrl()
                    setShowTooltip(true)
                }}> REFERRAL LINK </Button>
            </Tooltip>
        </>
    )

}