import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axiosService from "../../requests/axios";
import applicationFormSlice from "../../redux/slices/applicationForm";
import {RootState} from "../../redux/store";
import {Box, Button, Divider, Grid, TextField, Typography} from "@mui/material";
import {THEME_COLORS} from "../../constants/colors";
import DragDrop from "../../components/DragDrop";
import {SubmitHandler, useForm} from "react-hook-form";
import {CreateApplicationRequest} from "../../interfaces/requests/CreateAplication";
import TechnologiesSelect from "./components/TechnologiesSelect";
import CompanyLogo from "../../components/CompanyLogo";
import BountyDetailsChips from "../Bounties/components/BountyDetailChips";
import TargetCompensationRange from "./components/TargetCompensationRange";
import BountyDescription from "../Bounties/components/BountyDescription";
import {TechnologyResponse} from "../../interfaces/responses/Technology";
import AlertMessage from "../../components/AlertMessage";
import {
    AlertMessageType,
    MEDIUM_PAGE_SIZE,
    PATTERN_FOR_EMAIL,
    PATTERN_FOR_URL,
} from "../../constants/common";
import CustomLinearProgress from 'components/CustomLinearProgress';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { boxShadowMainCard } from 'constants/styles';


export default function CreateApplication() {
    const params = useParams()
    const dispatch = useDispatch();
    const [requestLoading, setRequestLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    const [technologies, setTechnologies] = useState<TechnologyResponse[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [showBountyDescription, setShowBountyDescription] = useState(false)
    const {register, handleSubmit, formState: { errors }} = useForm<CreateApplicationRequest>();
    const navigate = useNavigate()
    const applicationForm = useSelector((state: RootState) => state.applicationForm);
    const auth = useSelector((state: RootState) => state.auth);

    const onSubmit: SubmitHandler<CreateApplicationRequest> = data => {
         setRequestLoading(true);
        const formData = new FormData();

        for (const [key, value] of Object.entries(data)) {
            if (!!value) {
                formData.append(key, value)
            }
        }
        formData.append('resume', selectedFile as File);
        formData.append('technology_ids', JSON.stringify(technologies.map(technology => technology.id)));
        formData.append('bounty', String(applicationForm.bounty?.id!));
        formData.append('application_form', String(applicationForm?.id));

        axiosService.post('applications/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            setRequestLoading(false);
            navigate('/form-successful', {state: {
                recruiterFullName: applicationForm.recruiter?.first_name && applicationForm.recruiter.last_name ?
                `${applicationForm.recruiter.first_name} ${applicationForm.recruiter.last_name}` : applicationForm.recruiter?.email}})
        })
        .catch((err) => {
            setMessage({message: err.response.data.message, code: err.response.data.status_code});
            setRequestLoading(false);
        });

    }


    useEffect(() => {
        setLoading(true)
        axiosService.get(`application-form/${params.token}/`)
            .then((res) => {
                dispatch(
                    applicationFormSlice.actions.setApplicationForm({
                        id: res.data.id,
                        bounty: res.data.bounty,
                        recruiter: res.data.recruiter,
                        token: res.data.token
                    })
                );
                setLoading(false);
            })
            .catch((err) => {
                setMessage({message: err.response.data.message, code: err.response.data.status_code});
                setLoading(false);
            });

    }, [])

    const pageWidth = window.innerWidth

    return (
        <>
        { !loading  ?
        <Grid px={{xs:"1rem", sm:"3rem", md:"4rem", lg: "4.125rem"}}>
            <Box>{message &&
                <AlertMessage setMessage={setMessage} message={message.message} code={message.code}/>}</Box>
            <Grid px='clamp(3px, 5px, 5%)' alignItems="center" justifyContent="center" container direction="column"
                  sx={{wordBreak: 'break-all', textAlignLast: 'center', textAlign: 'center'}}>
                <CompanyLogo company={applicationForm.bounty?.company}/>
                <Typography variant="subtitle2" sx={{mt: 1}}>{applicationForm.bounty?.company.legal_name}</Typography>
                {applicationForm.bounty?.company.description &&
                    <Typography variant="body2" color={THEME_COLORS.contentAccessory} >
                        {applicationForm.bounty.company.description}
                    </Typography>}
                {!!auth.account ?
                    <>
                        <Typography variant="h3" sx={{cursor: 'pointer'}} onClick={()=> {navigate(`/bounty/${applicationForm.bounty?.id}`)}}>
                            {applicationForm?.bounty?.position?.title}
                        </Typography>
                    </> :
                    <>
                        <Typography variant="h3" >
                            {applicationForm?.bounty?.position?.title}
                        </Typography>
                    </>
                }
                <Box  m='auto' sx={{mt: 2}}>
                    <BountyDetailsChips bounty={applicationForm.bounty}/>
                </Box>
            </Grid>
            <Box height={50}/>
            <Divider sx={{m: "auto"}}/>
            <Box height={50}/>
            <Grid container justifyContent='space-around' direction='row' flexWrap='wrap-reverse'> 
                <Grid item width={{sm:"40rem", md:"20rem", lg: "35%"}}
                      boxShadow={pageWidth > MEDIUM_PAGE_SIZE ? boxShadowMainCard : "none" }
                      padding={{xs: "2rem 1rem", sm: "2.5rem 2rem", lg: "3rem 2rem"}}
                      sx={{
                          alignSelf: 'flex-end',
                          background: THEME_COLORS.surface,
                          borderRadius: "8px",
                          height: 'min-content',
                      }}>
                    <Typography variant="h3">
                        application form
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            error={!!errors.first_name}
                            label="First Name" required fullWidth
                                   sx={{
                                       mt: 2,
                                       background: THEME_COLORS.surface
                                   }}
                                   {...register('first_name', {required: true})}

                        />
                        {errors.first_name && (
                        <Box display='flex' mt={'3px'}>
                            <ErrorOutlineIcon sx={{height: '17px', color: THEME_COLORS.error}}/>
                            <Typography variant={'body5'} sx={{color: THEME_COLORS.error}}>First Name is required.</Typography>
                        </Box>)}
                        <TextField required label="Last Name" fullWidth
                                   sx={{mt: 2, background: THEME_COLORS.surface}}
                                   {...register('last_name',{required: true} )}/>
                        {errors.last_name && (
                        <Box display='flex' mt={'3px'}>
                            <ErrorOutlineIcon sx={{height: '17px', color: THEME_COLORS.error}}/>
                            <Typography variant={'body5'} sx={{color: THEME_COLORS.error}}>Last Name is required.</Typography>
                        </Box>)}
                        <TextField label="Email" required sx={{my: 2, background: THEME_COLORS.surface}}
                                   fullWidth {...register('email', {required: true, pattern: PATTERN_FOR_EMAIL})}/>
                        {errors.email && (
                        <Box display='flex' mt={'3px'}>
                            <ErrorOutlineIcon sx={{height: '17px', color: THEME_COLORS.error}}/>
                            <Typography variant={'body5'} sx={{color: THEME_COLORS.error}}>{errors.email.type === 'required' ? 'Email is required.' : 'Invalid email format'}</Typography>
                        </Box>)}
                        <DragDrop file={selectedFile} setFile={setSelectedFile}/>
                        <TechnologiesSelect values={technologies} setValue={setTechnologies}/>
                        <TextField label="LinkedIn" sx={{mt: 2, background: THEME_COLORS.surface}}
                                   fullWidth {...register('linkedin', {pattern: PATTERN_FOR_URL})}/>
                        {errors.linkedin && (
                        <Box display='flex' mt={'3px'}>
                            <ErrorOutlineIcon sx={{height: '17px', color: THEME_COLORS.error}}/>
                            <Typography variant={'body5'} sx={{color: THEME_COLORS.error}} >Invalid URl format</Typography>
                        </Box>)}
                         <TextField sx={{mt: 2, background: THEME_COLORS.surface }} fullWidth multiline  minRows={4} label="Cover Note"  {...register('note')}/>
                        {/*<TargetCompensationRange register={register}/>*/}
                        <Button
                            sx={{background: THEME_COLORS.secondaryVariant, mt: 2, height: 50, p: 'clamp(2px, 10px, 5%)'}}
                            type="submit"
                            variant="contained"
                            fullWidth
                            disabled={requestLoading}
                        >
                            Submit Application
                        </Button>
                    </form>
                </Grid>
                <>

                    {(!!applicationForm.bounty?.description || !!applicationForm.bounty?.technologies?.length) &&
                        <>
                <Grid
                    boxShadow={pageWidth > MEDIUM_PAGE_SIZE ? boxShadowMainCard : "none" }
                    display={pageWidth < MEDIUM_PAGE_SIZE && !showBountyDescription ? 'none' : 'flex'}
                    width={{sm:"48rem", md:"30rem", lg: "55%"}}
                    padding={{xs: "2rem 1rem", sm: "2.5rem 2rem", lg: "3rem 2rem"}}
                    alignSelf={'flex-end'}
                    sx={{
                    background: THEME_COLORS.surface,
                    borderRadius: "8px",
                    height: 'max-content',
                    mb: 2
                }}
                >

                    <BountyDescription bounty={applicationForm.bounty}/>
                </Grid>
                    {pageWidth < MEDIUM_PAGE_SIZE &&
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" mb={3}  onClick={() => setShowBountyDescription(!showBountyDescription)}>
                        <Typography sx={{mt: 2}} variant="subtitle3" color={(theme) => theme.palette?.primary?.dark}>
                            {showBountyDescription ? "LESS" : "MORE"}
                        </Typography>
                         { showBountyDescription ? <ExpandLessIcon fontSize="large" sx={{color: (theme) => theme.palette?.secondary?.main,  pt: "4px"}}/> :
                         <ExpandMoreIcon fontSize="large" sx={{color: (theme) => theme.palette?.secondary?.main, pt: "4px"}}/>}
                    </Grid>}
                            </>
                    }
                </>
            </Grid>
        </Grid> : <CustomLinearProgress/> }
        </>
    );
}