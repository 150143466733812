import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

import {Box, Button, Grid} from "@mui/material";

import {Document, Page, pdfjs} from 'react-pdf';


export default function CandidateResume() {
    const [numPages, setNumPages] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);


    function onDocumentLoadSuccess({numPages}: { numPages: number }) {
        setNumPages(numPages);
    }

    const {application} = useSelector((state: RootState) => state.applicationDetails);

    function isImage(url: string) {
        return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url.split('?')[0]);
    }

    function isPDF(url: string) {
        return /^https?:\/\/.+\.(pdf)$/.test(url.split('?')[0]);
    }

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    });

    return (
        <>
            {!!application?.resume &&
                <Grid my={2} container alignItems={'center'} justifyContent={'center'} direction={'column'}>
                    {isPDF(application.resume) &&
                        <>
                            <Document className={'pdf-resume'} file={application.resume} onLoadSuccess={onDocumentLoadSuccess}>
                                <Page height={1400} width={1000} pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false}/>
                            </Document>
                            <Grid>
                                {pageNumber - 1 > 0 &&
                                    <Button sx={{mr: 1}} onClick={() => setPageNumber(pageNumber - 1)}>Previous</Button>}
                                {(numPages && pageNumber + 1 <= numPages) &&
                                    <Button sx={{ml: 1}} onClick={() => setPageNumber(pageNumber + 1)}>Next</Button>}
                            </Grid>
                        </>
                    }
                    {isImage(application.resume) &&
                        <>
                            <Box
                                component="img"
                                sx={{height: '100%', width: '100%', maxHeight: '1400px', maxWidth: '1000px'}}
                                alt="resume"
                                src={application.resume}
                            />

                        </>
                    }
                </Grid>
            }
        </>
    );
}