import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import CompanyLogo from "../../../components/CompanyLogo";
import {THEME_COLORS} from "../../../constants/colors";
import {BountyApplicationsResponse, BountyResponse} from "../../../interfaces/responses/Bounty";
import BountyDetailsChips from "../../Bounties/components/BountyDetailChips";
import {amountWithCurrency} from "../../../utils/currency";
import {useNavigate} from "react-router-dom";
import ChevronRightSharpIcon from "@mui/icons-material/ChevronRightSharp";
import {Avatar} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";


interface BountyMainInfoProps {
    bounty: BountyResponse | BountyApplicationsResponse;
}

const BountyMainInfoCard: React.FC<BountyMainInfoProps & {redirectToBounty?: boolean}>   = ({bounty, redirectToBounty=true}) => {
    const navigation = useNavigate();
    const auth = useSelector((state: RootState) => state.auth);
    return (
        <Box>
            <Grid container alignItems='flex-end' direction='row' gap={3} sx={{mb: 3}}>
                <Grid alignSelf='flex-start'>
                    <CompanyLogo company={bounty.company}/>
                </Grid>
                <Grid container sx={{height: 'min-content', flex: 1, justifyContent: 'space-between'}}
                      direction='column'>
                    <Typography variant="subtitle2" sx={{height: 'min-content'}}>
                        {bounty.company?.legal_name}
                    </Typography>
                    {bounty.company?.description && <Typography variant="body2" color={THEME_COLORS.surfaceDivider}>
                        {bounty.company.description}
                    </Typography>}
                    <Grid container justifyContent="space-between" alignItems="center" sx={{height: 'min-content'}}>
                        {redirectToBounty && !!auth.account ?
                            <>
                            <Typography variant="subtitle1" onClick={()=> {navigation(`/bounty/${bounty.id}`)}}
                                    sx={{fontWeight: 500, color: THEME_COLORS.contentLeading, height: 'min-content', cursor: 'pointer'}}>
                            {bounty.position?.title}
                            </Typography>
                            </>
                            :
                            <>
                            <Typography variant="subtitle1" sx={{fontWeight: 500, color: THEME_COLORS.contentLeading, height: 'min-content'}}>
                            {bounty.position?.title}
                            </Typography>
                            </>
                        }

                        <Typography variant="subtitle2"
                                    sx={{color: THEME_COLORS.contentLeading, height: 'min-content',}}>
                            {amountWithCurrency(bounty.reward)} BOUNTY
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container width='fit-content' alignItems='flex-start'>
                <BountyDetailsChips bounty={bounty}/>
            </Grid>
        </Box>
    )
}

export const BountyMainInfoCardSmall: React.FC<BountyMainInfoProps> = ({bounty}) => {
    const navigation = useNavigate();

    return (
        <Box>
            <Grid container alignItems='flex-end' direction='row' gap={3} sx={{mb: '5px'}}>
                <Grid alignSelf='flex-start'>
                    <Avatar sx={{width: 65, height: 65, background: "linear-gradient(to bottom right, #8fc6b3, #d0e4de)", borderRadius: '8px'}} variant="square">
                        <Typography sx={{fontSize: 40, fontWeight: 600}}
                        color={THEME_COLORS.background}>{bounty.company?.legal_name[0]}</Typography>
                    </Avatar>
                </Grid>
                <Grid container sx={{height: 'min-content', flex: 1, justifyContent: 'space-between'}}
                      direction='column'>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Box display={'grid'}>
                        <Typography variant="body4" sx={{height: 'min-content', textTransform: 'uppercase', color: THEME_COLORS.contentAccessory }}>
                            {bounty.company?.legal_name}
                        </Typography>
                        {bounty.company?.description &&
                            <Typography variant="body5" color={THEME_COLORS.surfaceDivider}>
                                {bounty.company.description}
                            </Typography>}
                        </Box>
                        <Box display={'flex'} sx={{color: THEME_COLORS.contentAccessory}} onClick={() => navigation(`/bounty/${bounty.id}`)}>
                            <Typography variant="body3">
                                MORE
                            </Typography>
                            <ChevronRightSharpIcon sx={{color: (theme) => theme.palette?.secondary?.main}}/>
                        </Box>
                    </Grid>
                    <Grid container justifyContent="space-between" alignItems="center" sx={{height: 'min-content'}}>
                        <Typography variant="subtitle3" onClick={()=> {navigation(`/bounty/${bounty.id}`)}}
                                    sx={{fontWeight: 500, color: THEME_COLORS.contentLeading, height: 'min-content', cursor: 'pointer'}}>
                            {bounty.position?.title}
                        </Typography>
                        <Typography variant="subtitle3"
                                    sx={{color: THEME_COLORS.contentLeading, height: 'min-content',}}>
                            {amountWithCurrency(bounty.reward)} BOUNTY
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container width='fit-content' alignItems='flex-start'>
                <BountyDetailsChips bounty={bounty}/>
            </Grid>
        </Box>
    )
}
export default BountyMainInfoCard;